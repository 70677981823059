import { Component, OnInit, ViewChild, AfterContentInit, AfterViewInit, HostListener } from "@angular/core";
import { NgWizardConfig, THEME, NgWizardService } from "ng-wizard";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ProjetService } from "../../service/projet.service";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import mermaid from 'mermaid';
import { Location } from '@angular/common';
import { PartenaireService } from "../partenaire/partenaire.service";
import { any } from "underscore";
import { cpuUsage } from "process";

@Component({
  selector: "app-edit-project",
  templateUrl: "./edit-project.component.html",
  styleUrls: ["./edit-project.component.css"],
})

export class EditProjectComponent implements OnInit, AfterViewInit {
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    idField: '_id',
    textField: 'name',
    allowSearchFilter: false,
    enableCheckAll: false,
  };

  public dropdownProfileEventSettings: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'Il n\'y a plus d\'événement à affecter',
    idField: '_id',
    textField: 'name',
    allowSearchFilter: false,
    enableCheckAll: false
  };

  public dropdownSettingsPartenaire: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de donnés disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'rrf',
    textField: 'tradeName_rrf',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };

  public dropdownSettingsArca: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de donnés disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'fonction',
    textField: 'fonction',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };

  public statusList = ["Verrouillé", "En Cours", "Validé", "Refusé"];
  public isEditMode: boolean = false;
  public project: any;
  public isFirstSpetValid: boolean = false;
  public isSecondStepValid: boolean = false;
  public entityDetails: any;
  public tempSectedItems = [];


  public valideurCentrale = [
  ];

  public typeIntervenants = [
    { 'label': 'Affaires', 'code': '1', isChecked: false },
    { 'label': 'Editeurs DMS', 'code': '2', isChecked: false, keyWord: "EPDX", list: [], selectedItems: [] },
    {
      'label': 'Partenaires (Fourniseurs)',
      'code': '3',
      keyWord: "EPXX",
      list: [],
      isChecked: false,
      selectedItems: []
    },
    { 'label': 'Utilisateur centrale (Arca)', 'code': '4', isChecked: false, list: [], selectedItems: [] }
  ];

  projectEntities: any[] = [];
  projectTypeInervenant: any[] = [];
  profileNameValid: boolean = false;
  isFinalStepValid: boolean = false;
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    lang: {
      next: "Suivant",
      previous: "Précédent",
    },
    anchorSettings: {
      anchorClickable: false,
      markDoneStep: true,
    },
    toolbarSettings: {
      toolbarExtraButtons: [
        {
          text: "Enregistrer",
          class: "btn btn-info",
          event: () => {
            alert("Finished!!!");
          },
        },
      ],
    },
  };
  profile: any;
  profiles: any = [];
  projectId: any;
  profilesPossibleEvents: any[];
  profilesEdition: any;
  isIntervenantStepValid: boolean;
  flowChart: any;
  stringFlowChart: any = "";
  @ViewChild("mermaid") mermaidDiv;
  dms: any;
  shouldShowDms: any;
  fournisseurs = [];
  profileStepValid: boolean;
  is_complete: boolean = false;
  isSaving: boolean = false;
  ipn: any;
  personId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private ngWizardService: NgWizardService,
    private projService: ProjetService,
    private toastr: ToastrService,
    private router: Router,
    private _location: Location,
    private partenaireService: PartenaireService
  ) {
    this.ipn = sessionStorage.getItem('userUid');
    this.personId = sessionStorage.getItem('personId');
    const state = this.router.getCurrentNavigation().extras.state;

    if (state && state.projectId) {
      this.isEditMode = true;
      this.projectId = state.projectId;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    alert('By refreshing this page you may lost all data.');
  }
  backClicked() {
    this._location.back();
  }

  async ngOnInit() {
    this.getAllFonctions();
    this.partenaireService.getPartenaireByType('EPXX').subscribe((res: any) => {
      this.fournisseurs = res && res.result ? res.result : [];
      this.fournisseurs.forEach(fournisseur => { fournisseur.tradeName_rrf = `${fournisseur['tradename']} (${fournisseur['rrf']})` });
      this.typeIntervenants[2].list = this.fournisseurs;
    });

    await this.projService.loadEntitiesDetails().then(async jsonData => {
      this.entityDetails = jsonData;
      // var detailNatures = await this.projService.loadCodeNaturesReseau();

      // for (var val of this.entityDetails) {
      //   if (detailNatures != undefined && detailNatures.entities != undefined && detailNatures.entities.length > 0) {
      //     var valIndex = detailNatures.entities.findIndex(elt => elt.code_nature === val.code_nature)

      //     if (valIndex != undefined) {
      //       val['libelle_nature_clean'] = detailNatures.entities[valIndex].libelle_nature
      //     }
      //     else {
      //       val['libelle_nature_clean'] = val.libelle_nature
      //     }
      //   }
      // }
    });

    if (this.projectId) {
      this.getProjectForEdition();
    }
  }

  ngAfterViewInit(): void {
    mermaid.initialize({
      theme: "dark",
      securityLevel: 'loose',
    });
    mermaid.init();

  }

  updateFlowchart(graphDefinition) {
    const element: any = this.mermaidDiv.nativeElement;
    element.innerHTML = ''
    mermaid.render("graphDiv", graphDefinition, (svgCode, bindFunctions) => {
      element.innerHTML = svgCode;
    });
  }


  private getProjectForEdition() {
    this.projService.getProjectForEdition(this.projectId).then(response => {
      this.initProjectEditionData(response);
    });
  }

  addChartId(data: any[]) {
    if (data && data.length > 0) {
      for (var evt of data) {
        evt['chart_id'] = '("' + evt['name'] + '")';
        var dependencies = evt['dependencies']
        if (dependencies && dependencies.length > 0) {
          for (var dep of dependencies) {
            dep['chart_id'] = '("' + dep['name'] + '")';
            dep['id'] = data.find(d => d.name == dep.name).position;
          }
        }
      }
      return data;
    } else {
      return data
    }
  }

  initProjectEditionData(data) {
    this.project = data;
    this.project.projectEvents=this.project.events;
    this.project.projectEvents.forEach(e => e.isDisabled = false);
    this.projectEntities=this.project.perimeter;
    this.is_complete = data.is_complete;
    this.isFirstSpetValid = true;
    this.profilesEdition = this.project.profiles;
    this.profilesEdition.forEach(p=>{
      p.profileEvents=this.project.projectEvents.filter(e=>e.profile==p._id)
    })
    if (this.entityDetails) {
      this.initEditionPerimeter(this.projectEntities);
    }
  }


  buildFlowData(projectEvents: any[]) {
    var flowChartData = 'flowchart TB\n';
    if (projectEvents && projectEvents.length > 0) {
      for (var index = 0; index <= projectEvents.length - 1; index++) {
        var deps = projectEvents[index]['dependencies'];
        var nbDependencies = deps?deps.length:0;

        if (nbDependencies == 0 && index < 1 && projectEvents.length == 2) {
          flowChartData = flowChartData + 0 + '("' + projectEvents[index]['name'] + '")-->' + 1 + '("' + projectEvents[index + 1]['name'] + '")\n'
        }
        else if (projectEvents.length != 2) {
          if (index > 0 && nbDependencies > 0 && index <= projectEvents.length - 1) {
            for (var idx = 0; idx <= nbDependencies - 1; idx++) {
              flowChartData = flowChartData + deps[idx]['id'] + deps[idx]['chart_id'] + '-->' + projectEvents[index]['position'] + '("' + projectEvents[index]['name'] + '")\n'
            }
          }
        }
      }
    }
    this.updateFlowchart(flowChartData)
  }

  initEditionPerimeter(entities) {
    for (var val of entities) {
      if (this.entityDetails != undefined && val.code_nature != undefined && this.entityDetails.length > 0) {
        var valIndex = this.entityDetails.findIndex(elt => elt.code_nature === val.code_nature)
        if (valIndex != undefined && valIndex > -1) {
          this.entityDetails[valIndex]['isChecked'] = true
          this.isSecondStepValid = true;
        }
      }
    }
  }

  typeIntervantChanged(event: any, val: any, profil: any, list, profileName) {
    profil.typeIntervenants.forEach(p => {
      p.isChecked = false;
    })
    val.isChecked = true;

    for (let profile of this.profiles) {
      if (profile.name === profileName) {
        for (let typeIntervant of profile.typeIntervenants) {
          if (typeIntervant.code !== val.code) {
            typeIntervant.selectedItems ? typeIntervant.selectedItems = [] : null
          }
        }

      }
    }
    this.isProfileStepValid();
  }

  onSelectPartenaire(val, profileName) {

    for (let profile of this.profiles) {
      if (profile.name === profileName) {
        for (let typeIntervant of profile.typeIntervenants) {
          if (typeIntervant.code !== val.code) {
            typeIntervant.selectedItems ? typeIntervant.selectedItems = [] : null
          }
        }
      }
    }
  }

  onSelectAll(items: any) {
  }

  onItemDeSelectAll(items: any) {
  }

  onSiteCheck(event: any, entry: any) {
    var entite = this.entityDetails.find(elt => elt.code_nature == entry.code_nature)
    if (entite != undefined) {
      if (event.target.checked) {
        entite['isChecked'] = true
        entry['isChecked'] = true
      }
      else {
        entite['isChecked'] = false
        entry['isChecked'] = false
      }

      this.projectEntities = []
      for (var val of this.entityDetails) {
        if (val != undefined && val.isChecked) {
          this.projectEntities.push(val);
        }
      }

    }
    this.checkSecondStepFormValidity();
  }

  onChangeStatus(eventEmitted, event) {
    if (eventEmitted.target.value) {
      event.status = eventEmitted.target.value;
    }
  }

  onPositionChange(eventEmitted, event) {
    if (eventEmitted.target.value) {
      event.position = eventEmitted.target.value
    }
  }

  deleteEvent(projectEventId: any) {
    const index = this.project.projectEvents.findIndex(event => event._id === projectEventId);
    this.project.projectEvents.splice(index, 1);
  }

  resetWizard(event: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  goToPrevious(preset?) {
    // if (!preset) {
    //   this.profiles = [];
    //   this.profilesPossibleEvents = undefined;
    // }
    this.ngWizardService.previous();
  }

  checkSecondStepFormValidity() {
    this.isSecondStepValid = this.projectEntities && this.projectEntities.length > 0;
  }

  checkIntervenantStepFormValidity() {
    this.isIntervenantStepValid = this.projectTypeInervenant && this.projectTypeInervenant.length > 0;
  }

  validateSelection(val) {

    val['selectedItems'] = this.tempSectedItems.slice();
    this.isProfileStepValid();
    this.tempSectedItems = [];
  }

  cancelSelection() {
    this.tempSectedItems = [];
    this.isProfileStepValid();
  }

  initModal(val) {
    if (val != undefined && val.selectedItems != undefined && val.selectedItems.length > 0) {
      this.tempSectedItems = val['selectedItems']
    }
  }

  goToNext(tabNum) {
    if (tabNum === 1) {
      this.project.availablePositions = [];
      let position = 1;
      this.project.projectEvents.forEach(event => {
        this.project.availablePositions.push({ positionId: position, name: position });
        event.position = position;
        if (position === 1) {
          event.status = 'En Cours';
        } else {
          event.status = 'Verrouillé'
        }
        position++;
      });

      this.project.projectEvents.forEach(event => {
        if (event.position !== 1 && (event.dependencies == null || event.dependencies.length == 0)) {
          event.dependencies = [];
          event.possibleDependencies = this.project.projectEvents.filter(evt => evt._id !== event._id && evt.position < event.position);
          const even = this.project.projectEvents.find(evt => evt.position === event.position - 1);
          even ? event.dependencies.push({ _id: even._id, name: even.name }) : undefined;
        }
        position++;
      });

      this.ngWizardService.next();

    } else {
      this.profiles = this.isEditMode ? this.profilesEdition : this.profiles ? this.profiles : [];
      this.profiles.forEach(p => {
        p.typeIntervenants = p.typeIntervenants && p.typeIntervenants.length === this.typeIntervenants.length ? p.typeIntervenants : JSON.parse(JSON.stringify(this.typeIntervenants));
        p.typeIntervenants.forEach(interv => {
          if (interv.code === '3') {
            interv.list = JSON.parse(JSON.stringify(this.fournisseurs));
          }
          if (interv.code === '2') {
            interv.dms = this.dms;
          }
        })
      });


      this.onEventProfileDSelected(null,null);
      this.ngWizardService.next();
      if (tabNum === 3) {
        this.getDmsByPerimetre();
        if (this.profiles && this.profiles.length) {
          const countEvent = this.project?.projectEvents?.length;
          let countEventProfile = 0;
          this.profiles.forEach(p => {
            countEventProfile += p.profileEvents.length;
          });
          this.isFinalStepValid = countEvent === countEventProfile;
          this.isProfileStepValid();
          if (this.isEditMode) {
            this.isFinalStepValid = true;
          }
        }
      }
      if (tabNum === 2) {
        this.project.projectEvents = this.addChartId(this.project.projectEvents);
        this.buildFlowData(this.project.projectEvents);
      }
      if (tabNum === 4) {
        this.isFinalStepValid = true;
        this.shouldShowDms = false;
        for (var prof of this.profiles) {
          for (var typeIntervenant of prof.typeIntervenants) {
            if (typeIntervenant != undefined && typeIntervenant.code == 3 && typeIntervenant.isChecked) {
              this.getEditeursFournisseur(typeIntervenant)
            }

            if (typeIntervenant != undefined && typeIntervenant.code == 4 && typeIntervenant.isChecked) {
              this.getValideurCentrale(typeIntervenant)
            }
          }


        }
      }
    }
  }

  onProjectChange(event: any) {
    if (event) {
      this.project = event;
      this.isFirstSpetValid = this.project.isStepValid;

      if (this.project.profiles) {
        this.profiles = undefined;
        this.profiles = this.project.profiles;
      }

      this.profilesPossibleEvents = this.project.projectEvents;
    }
  }

  onProfileInputChange(event: any) {
    let pattern = new RegExp('^[a-z0-9]+$')
    this.profileNameValid = event.target.value && event.target.value.length;
  }

  addProfile() {
    this.profiles.push({
      name: this.profile,
      profileEvents: [],
      typeIntervenants: JSON.parse(JSON.stringify(this.typeIntervenants))
    });
    this.profileNameValid = false;
    this.profile = '';
    this.isProfileStepValid();
  }

  onDependencySelected(eventEmitted: any, event: any, projectEvents: any) {
    if (event.dependencies && event.dependencies.length > 0 && event.status === 'En Cours') {
      event.status = 'Verrouillé';
    }
    const data = this.addChartId(projectEvents);
    this.buildFlowData(data);
  }

  onDependencyDeSelected(eventEmitted: any, event: any, projectEvents: any) {
    if ((!event.dependencies || event.dependencies.length === 0) && event.status === 'Verrouillé') {
      event.status = 'En Cours';
    }
    const data = this.addChartId(projectEvents);
    this.buildFlowData(data);
  }

  onEventProfileSelected() {
    let pes = this.profiles.flatMap(p => p.profileEvents).map(e => e._id);
    this.profilesPossibleEvents = this.project.projectEvents.filter(pev => !pes.includes(pev._id));
    this.isProfileStepValid();
  }

  getData(profileEvents) {
    let events = Object.assign([], this.project.projectEvents);
    events.forEach(e => {
      if (this.profilesPossibleEvents.map(p => p._id).includes(e._id)||profileEvents.map(p => p._id).includes(e._id)) {
        e.isDisabled = false;
      } else e.isDisabled = true;
      return events;
    });
    return events
  }

  onEventProfileDSelected(event, profil) {
    let pes = this.profiles.flatMap(p => p.profileEvents).map(e => e._id);
    this.profilesPossibleEvents = this.project.projectEvents.filter(pev => !pes.includes(pev._id));
    this.isProfileStepValid();
  }

  checkIsCompleteProject() {
    let isNotCompleteCalssList: HTMLCollectionOf<Element> = document.getElementsByClassName("is_not_complete_class");
    isNotCompleteCalssList && isNotCompleteCalssList.length > 0 ? this.is_complete = false : this.is_complete = true;
  }

  saveProject() {
    this.checkIsCompleteProject();
    this.isSaving = true;
    this.project.isBrouillon = false;
    this.prepareData();
    this.projService.createProject(this.project).then(response => {
      this.isSaving = false;
      if (response) {
        this.router.navigateByUrl('project/home');
      }
    })
  }

  saveEditedProject() {
    this.checkIsCompleteProject();
    this.isSaving = true;
    this.project.isBrouillon = false;
    this.prepareData();
    this.projService.updateProject(this.projectId, this.project).then(response => {
      this.isSaving = false;
      if (response) {
        this.router.navigateByUrl('project/home');
      }
    });
  }

  saveBrouillon() {
    this.checkIsCompleteProject();
    this.project.isBrouillon = true;
    this.prepareData();
    if (this.isEditMode) {
      this.projService.updateProject(this.projectId, this.project).then(response => {
        this.isSaving = false;
        if (response) {
          this.router.navigateByUrl('project/home');
        }
      });
    } else {
      this.projService.createProject(this.project).then(response => {
        if (response) {
          this.router.navigateByUrl('project/home');
        }
      })
    }
  }

  private prepareData() {
    this.project.profiles = this.profiles;
    this.project.projectEntities = this.projectEntities;
    this.project.is_complete = this.is_complete;
    this.project.login = this.ipn;
    if(this.personId!=null){
      this.project.login = this.personId;
    }
  }

  public deleteProfile(profil: any) {
    const index = this.profiles.findIndex(p => p.name === profil.name);
    this.profiles.splice(index, 1);
  }

  private getAllFonctions() {
    this.projService.getAllFonctions().then(response => {
      if (response) {
        this.valideurCentrale = response;
      }
    });
  }

  private getDmsByPerimetre() {
    const codesNatures = this.projectEntities.map(v => v.code_nature).filter(v => v).join(',');
    this.partenaireService.getPartenaireDms(codesNatures).subscribe((result: any) => {
      if (result && result.result) {
        this.dms = result.result;
        this.getEditeursDms();
      }
    });
  }

  private getEditeursDms() {
    const rrfs = this.dms.map(d => d.rrf).join(',');
    this.projService.searchEditeursDms(rrfs).then(response => {
      if (response && response.result) {
        this.dms.forEach(d => {
          const resp = response.result.find(r => r.rrf === d.rrf);
          if (resp) {
            d.valideurs = resp.editeurs;
            d.complete = resp.editeurs && resp.editeurs.length;
          }
        });
      }
    });
  }

  isProfileStepValid() {
    this.profileStepValid = false;
    if (this.profiles.length === 0) return;
    for (const profil of this.profiles) {
      if (!profil.profileEvents || profil.profileEvents.length === 0) {
        return;
      }
      const profilInterv = profil.typeIntervenants ? profil.typeIntervenants.find(tp => tp.isChecked) : undefined;
      if (!profilInterv) {
        return;
      }
      if (profilInterv.code === '3' && (!profilInterv.selectedItems || profilInterv.selectedItems.length === 0)) {
        return;
      }
      if (profilInterv.code === '4' && (!profilInterv.selectedItems || profilInterv.selectedItems.length === 0)) {
        return;
      }
    }
    if (this.profilesPossibleEvents && this.profilesPossibleEvents.length > 0) return;
    this.profileStepValid = true;
  }

  getEditeursFournisseur(val) {
    const rrfs = val.selectedItems.map(f => f.rrf).join(',');
    this.projService.searchEditeursDms(rrfs).then(response => {
      if (response && response.result) {
        val.selectedItems.forEach(f => {
          const resp = response.result.find(r => r.rrf === f.rrf);
          if (resp) {
            f.valideurs = resp.editeurs;
            f.complete = resp.editeurs && resp.editeurs.length;
          }
        });
      }
    });
  }



  async getValideurCentrale(val) {
    const fonctions = val.selectedItems.map(f=>f.fonction).join(',');
    const codesNatures = this.projectEntities.map(v => v.code_nature).filter(v => v).join(',');
    var wdyMembers = await this.projService.searchWdyAdaMembers();
    var wdyMembersRes = []
    if (wdyMembers != undefined && wdyMembers.result != undefined && wdyMembers.result.length > 0) {
      wdyMembersRes = wdyMembers.result;
    }

    this.projService.searchValideurCentrale(codesNatures, fonctions).then((result: any) => {
      if (result) {
        val.ipns = result.map(i => { return { mail: i.mail, code_entite: i.code_entite,lastName:i.nom } });
        result = result.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.mail === value.mail && t.fonction === value.fonction&&t.nom===value.nom
          ))
        )
        console.log(result)
        val.selectedItems.forEach(f => {
          const resp = result.filter(r=>r.fonction==f.fonction);
          if (resp && resp.length > 0) {
            for (var elt of resp) {
              // var indexVal = wdyMembersRes.findIndex(ipn => ipn == elt.ipn)
              // if (indexVal > -1) { elt['droit_ada'] = true } else { elt['droit_ada'] = false }
              if(elt.mail){
                elt['droit_ada'] = true } else { elt['droit_ada'] = false }
            }

            f.valideurs = resp;
          }

        });
      }
    });
  }


  downloadArca(interv) {
    let ipnKo = interv.selectedItems.flatMap(o => o.valideurs).filter(o => !o.droit_ada).map(o => o.ipn).join('\r\n');
    this.projService.saveAsTextFile(ipnKo);
  }

}
