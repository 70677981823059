import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { ProjetService } from 'src/app/service/projet.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { error } from "protractor";
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/service/user.service";
@Component({
  selector: "app-wkf-event",
  templateUrl: "./wkf-event.component.html",
  styleUrls: ["./wkf-event.component.css"]
})
export class WkfEventComponent implements OnInit {
  public eventlist: any = [];
  public projet: any;
  public rrf: any;
  public userType: any;
  public today: any = new Date().toISOString().split('T')[0];
  public eventObj: any;
  private projectId: any;
  entityEvents: any[] = [];
  entityForm: FormGroup;
  entity: any;
  userUid: string;
  projectIds: any = [];
  private idRrf: any;
  @Input() childObj: any;
  file: any;
  path: any;
  projectName: any;
  currentUser: string;
  personId: string;
  login: string;
  entityName: any;
  mail: string;
  constructor(private projectService: ProjetService,    private userService: UserService, private fb: FormBuilder, private _location: Location, private toastr: ToastrService, private router: Router, private cdRef: ChangeDetectorRef) {
    this.mail= sessionStorage.getItem('mail');
    this.userUid = sessionStorage.getItem('userUid');
    this.personId = sessionStorage.getItem('personId');
    this.login = this.userUid;
    if(this.personId!=null){
      this.login = this.personId;
    }
        this.userService.userTypeObservable.subscribe(user => {
      this.currentUser= user;
    })
    if (this.router.getCurrentNavigation()) {
      this.projectId = this.router.getCurrentNavigation().extras.state.projectId;
      this.idRrf = this.router.getCurrentNavigation().extras.state.idRrf;
      this.userType = this.router.getCurrentNavigation().extras.state.userType;
      this.projectName = this.router.getCurrentNavigation().extras.state.projectName;
    }
  }

  ngOnInit() {
    if (this.childObj != null) {
      this.projectId = this.childObj.projectId;
      this.idRrf = this.childObj.idRrf;
      this.userType = this.childObj.userType;
      this.projectName=this.childObj.projectName;
      this.entityName=this.childObj.entityName;
    }
    this.getProjectEntityEvents();
  }


  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  backClicked() {
    this._location.back();
  }


  getProjectEntityEvents() {
    this.entityForm = this.fb.group({});
    this.projectService.getProjectEntityEvents(this.projectId, this.idRrf).then(response => {
      this.entityEvents = response ? response.result : [];
      this.projectIds = this.entityEvents.map(ev => ev.event_entity_id);
      for (let entity of this.entityEvents) {
        for (let attr of entity.event_attributes) {
          if (attr._id !== undefined && attr.type != 'checkbox') {
            if (attr.required) {
              this.entityForm.addControl(attr._id, new FormControl({ value: attr.value, disabled: !this.isValideur(entity) }, [Validators.required, (attr.type === "phone" || attr.type === "email") ? Validators.pattern(attr.regex) : Validators.pattern(null)]));
            } else {
              this.entityForm.addControl(attr._id, new FormControl({ value: attr.value, disabled: !this.isValideur(entity) }, [(attr.type === "phone" || attr.type === "email") ? Validators.pattern(attr.regex) : Validators.pattern(null)]));
            }
          }
        }
      }
      console.log(this.entityEvents)
    });
  }

  onChangeCheckbox(event, v, item) {
    if (event.target.checked) {
      if (item.value != null) {
        item.value.push(v.label);
      } else {
        item.value = [v.label];
      }
    } else {
      item.value = item.value.filter(i => i != v.label)
    }
  }

  private checkEventAttributeValidity(event) {
    let isValid = false;
    if (event.event_attributes) {
      const checkbox = event.event_attributes.find(attribute => attribute.required && attribute.type === 'checkbox');
      if (checkbox?.value && checkbox.value.length > 0) {
        isValid = true;
      }

    }
    return isValid;
  }


  isValideur(event) {
    return (this.userType == "admin" ||
      (event.responsable && ((event.responsable.map(r => r == null ? '' : r.login).indexOf(this.login) > -1))||
      (event.responsable.map(r => r == null ? '' : r.mail).indexOf(this.mail) > -1)));
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  validate(event, item) {
    event.next_status = 'Validé';
    if (this.file) {
      const fichier = new FormData();
      fichier.append('file', this.file, event.project_event_id + "_" + this.file.name);
      this.projectService.uploadFile(fichier).then(response => {
        if (response) {
          event.event_attributes.filter(e => e.type == item.type)[0]['filename'] = this.file.name;
          event.event_attributes.filter(e => e.type == item.type)[0]['filepath'] = response.result;
          this.file = null;
          this.updateEvent(event);
        }
      });
    } else {
      this.updateEvent(event);
    }
  }

  isDisabled(event) {
    let ids = event.event_attributes.map(e => e._id);
    let isFile = event.event_attributes.filter(e => (e.type == 'template' && e.uploadRequired) || e.type == 'file').length > 0;
    let isCheckbox = event.event_attributes.filter(e => e.type == 'checkbox').length > 0;
    return (Object.keys(this.entityForm.controls).filter(k => ids.indexOf(k) > -1).map(k => this.entityForm.controls[k].status).filter(t => t == 'INVALID').length > 0) || (isFile && this.file == null)
      || (isCheckbox && !this.checkEventAttributeValidity(event))
  }

  updateEvent(event) {
    event.entity_name = this.entityName;
    event.valideur=this.currentUser['firstName']+ " "+this.currentUser['lastName'];
    this.projectService.updateProjectEventStatus(event,this.idRrf,this.projectName ,this.entityName).then(async response => {
      this.entityEvents = [];
      this.toastr.success('L\'évènement a été validé.');
      this.ngOnInit();
      this.projectService.setEvent(this.projectIds)

    });
  }

  download(item) {
    this.projectService.downloadFile(item.filepath, item.filename);
    this.toastr.success('Le fichier a été téléchargé.');

  }

  refuse(event) {
    event.next_status = 'Refusé';
    event.event_attributes.forEach(e => {
      e.value = null;
    });
    this.projectService.updateProjectEventStatus(event,this.idRrf,this.projectName ,this.entityName).then(async response => {
      this.toastr.success('L\'évènement a été refusé.');
      this.ngOnInit();
      this.projectService.setEvent(this.projectIds)
    });
  }



}
