import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ProjetService } from "../../service/projet.service";
import { Router } from "@angular/router";
import { UntypedFormBuilder } from "@angular/forms";
import * as _ from "underscore";
import { Location } from '@angular/common';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Ng2SearchPipe } from "ng2-search-filter";
@Component({
  selector: "app-tab-details",
  templateUrl: "./tab-details.component.html",
  styleUrls: ["./tab-details.component.css"],
})
export class TabDetailsComponent implements OnInit {
  p: number = 1;
  numItemsPage: number = 20;
  public statusAdvancedList = [];
  public eventStatusList = [];
  public filteredList = [];
  public projectId: any;
  globalProjectEnteties = [];
  globalGroupPlaqueList = [];
  project: any;
  term: any;
  drsList: any = [];
  groupeList: any = [];
  plaqueList: any = [];
  drsSelectedList = [];
  groupeSelectedList = [];
  plaqueSelectedList = [];
  groupeGlobalList = [];
  plaqueGlobalList = [];
  rrfGroupeList = [];
  rrfPlaqueList = [];
  childObj = {};
  private readonly userType: any;
  dropdownSettingsNature: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'libelle_nature',
    textField: 'libelle_nature',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsPartenaire: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'code_dr',
    textField: 'direction_regionale',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsGroupe: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'groupe',
    textField: 'groupe',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsPlaque: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'plaque',
    textField: 'plaque',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  userUid: string;
  pipe = new Ng2SearchPipe();
  naturesList: any = [];
  natureSelectedList: any = [];
  drsOriginList: any[];
  natureOriginList: any[];
  avancements: any = [{ label: "Verrouillé", css: "locked", checked: false }, { label: "En Cours", css: "in-progress", checked: false }, { label: "Validé", css: "validated", checked: false }, { label: "Refusé", css: "refused", checked: false }]
  events: any;
  selectedStatus: any;
  idRrf: any;
  eventProfiles: any;
  projectEntities: any;
  projectName: any;
  personId: string;
  login: string;
  mail: any;

  constructor(
    private fb: UntypedFormBuilder,
    private projectService: ProjetService,
    private router: Router,
    private _location: Location,
    private cd: ChangeDetectorRef
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state?.projectId && state.userType) {
      this.projectName = state.projectName;
      this.projectId = state.projectId;
      this.userType = state.userType;
      this.idRrf = state.idRrf;
    } else {
      this.router.navigateByUrl('project/home');
    }
    this.mail=sessionStorage.getItem('mail');
    this.userUid = sessionStorage.getItem('userUid');
    this.personId = sessionStorage.getItem('personId');
    this.login = this.userUid;
    if(this.personId!=null){
      this.login = this.personId;
    }
  }

  backClicked() {
    this._location.back();
  }

  updateprojectEvnets(res: any, projectEvents: any) {
    for (let entity of projectEvents) {
      if (entity.code_entite === res[0].id_rrf) {
        for (let ev of entity.events) {
          let results = res.filter(r => ev.project_event === r.project_event);
          if (results != null) {
            ev.current_status = results[0].current_status;
            this.cd.detectChanges();
          }
        }
      }
    }
  }
  ngOnInit() {
    this.projectService.event$.subscribe(ids => {
      if (ids.length > 0 ) {
        this.projectService.getEventEntitiesByIds(ids).then((res): any => {
          this.updateprojectEvnets(res['result'], this.projectEntities);
          this.updateprojectEvnets(res['result'], this.globalProjectEnteties);
        })
      }
    })
    this.getProject();
  }

  getProject() {
    this.projectService.getProjectEntitiesDetails(this.projectId, this.login,this.mail, this.userType, this.idRrf,this.p).then(response => {
      if (response?.result) {
        this.projectEntities = response.result;
        this.globalProjectEnteties.push(...response.result);

        for (let en of this.globalProjectEnteties) {
          let obj = { code_dr: en['code_dr'], direction_regionale: en['direction_regionale'].trim() };
          this.drsList.push(obj);
          this.naturesList.push({ libelle_nature: en.libelle_nature });
          this.groupeList.push({ groupe: en.groupe })
          this.plaqueList.push({ plaque: en.plaque })
        }
        this.drsList = [...new Map(this.drsList.map((item, key) => [item['code_dr'], item])).values()];
        this.drsOriginList = [...this.drsList];
        this.naturesList = [...new Map(this.naturesList.map((item, key) => [item['libelle_nature'], item])).values()];
        this.natureOriginList = [...this.naturesList];
        this.groupeList = [...new Map(this.groupeList.map((item, key) => [item['groupe'], item])).values()];
        this.groupeGlobalList = [...this.groupeList];
        this.plaqueList = [...new Map(this.plaqueList.map((item, key) => [item['plaque'], item])).values()];
        this.plaqueGlobalList = [...this.plaqueList];

        this.events = this.projectEntities[0].events;
        this.events.forEach(e => {
          e.avancements = JSON.parse(JSON.stringify(this.avancements));
        })
      }
    });
  }


  goToEntityEvents(event, entity) {
    this.childObj = { projectId: this.projectId, idRrf: entity.code_entite, userType: this.userType,projectName:this.projectName,entityName:entity.nom_usuel_renault }
    entity.isClicked = true;
    // this.router.navigateByUrl("/project/events/details", {state: {projectId: this.projectId, idRrf: entity.code_entite, userType: this.userType}});
  }

  getEntityEventsProfile(entity) {
    this.projectService.getProjectEntityEvents(this.projectId, entity.code_entite).then(response => {
     this.eventProfiles=response.result;
     console.log(this.eventProfiles)
    });
  }


  selectAll(items, key) {
    if (key == 'nature') {
      this.natureSelectedList = items;
    }
    if (key == 'dr') {
      this.drsSelectedList = items;
    }
    if (key == 'groupe') {
      this.groupeSelectedList = items;
    }
    if (key == 'plaque') {
      this.plaqueSelectedList = items;
    }
    this.updateStructure();
  }

  updateStructure() {
    this.p=1;
    this.projectService.findStructure(this.natureSelectedList, this.drsSelectedList, this.groupeSelectedList, this.plaqueSelectedList, this.events,this.projectId,this.p,this.globalProjectEnteties.map(e=>e.code_entite)).then(res => {
      let entities=res;
      if (this.natureSelectedList.length == 0) {
        this.naturesList = this.natureOriginList.filter(dr => entities.map(r => r['libelle_nature']).indexOf(dr.libelle_nature) > -1);
      }
      if (this.drsSelectedList.length == 0) {
        this.drsList = this.drsOriginList.filter(dr => entities.map(r => r['direction_regionale']).indexOf(dr.direction_regionale) > -1);
      }
      if (this.groupeSelectedList.length == 0) {
        this.groupeList = this.groupeGlobalList.filter(dr => entities.map(r => r['groupe']).indexOf(dr.groupe) > -1);
      }
      if (this.plaqueSelectedList.length == 0) {
        this.plaqueList = this.plaqueGlobalList.filter(dr => entities.map(r => r['plaque']).indexOf(dr.plaque) > -1);
      }
      this.projectEntities= res;
    });
  }


  filterData() {// Consider moving this as a class member
  let entities=this.pipe.transform(this.globalProjectEnteties, this.term).map(e=>e.code_entite);

  this.projectService.findStructure(this.natureSelectedList, this.drsSelectedList, this.groupeSelectedList, this.plaqueSelectedList, this.events,this.projectId,this.p,entities).then(res => {
    this.projectEntities= res;
  });
  }


  export() {
    this.projectService.findStructure(this.natureSelectedList, this.drsSelectedList, this.groupeSelectedList, this.plaqueSelectedList, this.events,this.projectId,null,this.globalProjectEnteties.map(e=>e.code_entite)).then(response => {
      if (response) {
        let entities = response
        let result = entities.map(e => {
          let events = {};
          e.events.forEach(ev => {
            events[ev.name] = ev.current_status;
            ev.event_attributes.forEach(a => {
              if (a.type != 'file' && a.type != 'template') {
                events[ev.name] += ";" + (ev.current_status == "Validé" ? (a.label + " " + (a.value != null ? a.value : "")) : "");
              } else {
                events[ev.name] += ";" + (ev.current_status == "Validé" ? (a.label + " " + (a.filename != null ? a.filename : "")) : "");
              }
            })
          });
          return {
            ...{
              "Direction regionale": e.direction_regionale,
              "Groupe": e.groupe,
              "RRF": e.code_entite,
              "Raison sociale": e.raison_sociale,
              "Nom usuel": e.nom_usuel_renault,
              "Nature de site": e.libelle_nature,
            }, ...events
          }
        });
        this.projectService.saveAsCsv(result, 'avancement')
      }
    });
  }
}
