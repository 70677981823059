import { ChangeDetectorRef, Component, OnInit, TemplateRef } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ProjetService } from "../../service/projet.service";
import { Router } from "@angular/router";
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { ToastrService } from 'ngx-toastr';
import { ProjectLight } from "../../models/project.model";
import { BsModalService } from "ngx-bootstrap/modal";
import { UserService } from "src/app/service/user.service";

declare var $: any;

@Component({
  selector: "app-projets",
  templateUrl: "./projets.component.html",
  styleUrls: ["./projets.component.css"],
})
export class ProjetsComponent implements OnInit {

  public projets: ProjectLight[] = [];
  public isAdmin: boolean = false;
  public userType;
  public rrfList = [];
  public activeTabName: string = 'first';
  public p: number = 1;
  public isFirst: boolean = true;
  public isWorkflowLoaded: boolean = false;
  public isFirstTabContent: boolean = true;
  public isSecondTabContent: boolean = false;
  userIdRrf: any;
  projectToDelete: any;
  modalRef: any;
  userUid: string;
  personId: string;
  login: string;
  mail: string;

  constructor(
    private fb: UntypedFormBuilder,
    private projetService: ProjetService,
    private router: Router,
    public cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    registerLocaleData(fr);
    this.userService.userTypeObservable.subscribe(user => {
      console.log('user is : ', user);
      this.userType = sessionStorage.getItem('userType');
      this.userIdRrf = sessionStorage.getItem('userIdRrf');
      this.userUid = sessionStorage.getItem('userUid');
      this.personId = sessionStorage.getItem('personId');
      this.login = this.userUid;
      if(this.personId!=null){
        this.login = this.personId;
      }
      this.mail=sessionStorage.getItem('mail');
      this.getProjectByUserType();
    })

  }

  getProjectByUserType() {
    if (this.userType === 'admin' || this.userType === 'rsi') {
      this.getProjets();
    } else {
      this.getUserProjets();
    }
  }

  changeTab(tabNum) {
    this.activeTabName = tabNum;
    if (tabNum == 'first') {
      this.isFirstTabContent = true;
      this.isSecondTabContent = false;
    }
    if (tabNum == 'second') {
      this.isFirstTabContent = false;
      this.isSecondTabContent = true;
    }
  }

  createProject() {
    this.router.navigateByUrl("/project/workflow");
  }

  getProjets() {
    this.projetService.getProjets(this.userType === 'admin' ? undefined : this.userIdRrf).then((response: any) => {
      this.projets = response ? response?.result.filter(p => p.status != 'BROUILLON' || (p.status == 'BROUILLON' && p.login == this.login)
      || (p.status == 'BROUILLON' && p.mail == this.mail)) : [];
      if(this.userType!='admin'){
        this.projets=this.projets.filter(p=>p.status!='FINISH');
      }
      console.log(this.projets)
    });
  }

  private getUserProjets() {
    this.projetService.getUserProjets(this.userIdRrf, this.login,this.mail, this.userType).then((response: any) => {
      this.projets = response ? response?.result : [];
      this.projets=this.projets.filter(p=>p.status!='FINISH');
    });
  }

  gotoProjectDetails(project) {
    if (this.userType === 'rsi') {// is rsi
      this.router.navigate(['/project/details'], { state: { projectName: project.name, projectId: project._id, idRrf: this.userIdRrf, userType: this.userType } });
    } else {
      this.router.navigate(['/project/details'], { state: { projectName: project.name, projectId: project._id, userType: this.userType } });
    }
  }

  editProject(projectId: any) {
    this.router.navigate(['/project/workflow'], { state: { projectId: projectId } });
  }

  deleteProject(projectId: any) {
    this.projetService.deleteProject(projectId).then(response => {
      if (response) {
        this.modalRef?.hide();
        this.toastr.success('Le projet a bien été supprimé.');
        this.ngOnInit();
      }
    });
  }

  finishProject(projectId: any) {
    this.projetService.updateProjectStatus('FINISH',projectId).then(response => {
      if (response) {
        this.modalRef?.hide();
        this.toastr.success('Le projet est terminé.');
        this.ngOnInit();
      }
    });
  }

  setProfileResponsable(project: any) {
    this.router.navigate(['/project/profiles'], { state: { projectName: project.name, projectId: project._id, idRrf: this.userIdRrf } });
  }

  onPanelShown($event) {
  }

  openModal(template: TemplateRef<any>, project) {
    this.projectToDelete = project;
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-sm' });
  }

}
