import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from '../service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private user = {};
  constructor(private authService: AuthService, private router: Router, private userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _isLocalhost = window.location.port.indexOf('4200') > -1
    if (_isLocalhost) {
      // sessionStorage.setItem('userType', 'admin');
      sessionStorage.setItem('userType', 'admin');
      // sessionStorage.setItem('userType', 'user');
      sessionStorage.setItem('userIdRrf', '9');
      sessionStorage.setItem('userUid', 'd148409');
      // sessionStorage.setItem('personId', '9000005730932');
      sessionStorage.setItem('mail', 'thierry.chene@renault.com');
      this.user['firstName'] = 'Felix';
      this.user['lastName'] = 'MWAKA';
      this.user['uid'] = 'd245494';
      this.user['userType'] = "admin";
      this.user['userIdRrf'] = '00000001';
      sessionStorage.setItem('currentUser', JSON.stringify(this.user));
      this.userService.userTypeHasChanged(this.user);
      return true;
    }

    var tokenIsValid = this.authService.TokenValid()
    if (!tokenIsValid || !this.authService.isAuthorized()) {
      this.router.navigate(['/login']);
      return false;
    }
    else {
      let user = JSON.parse(sessionStorage.getItem('currentUser'));
      user ? this.userService.userTypeHasChanged(user) : undefined;
      return true;
    }
  }

}
