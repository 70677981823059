import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ProjetService } from "../../service/projet.service";
import { ToastrService } from "ngx-toastr";
import { FormControl } from '@angular/forms';
import { Observable, Observer, of } from 'rxjs';
import { startWith, map, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
export interface Person {
  fullName: any;
  login: string;
  firstName: string;
  lastName: string;
  mail: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-project-events-profiles',
  templateUrl: './project-events-profiles.component.html',
  styleUrls: ['./project-events-profiles.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProjectEventsProfilesComponent implements OnInit {
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de affaire disponibles',
    idField: 'code_entite',
    textField: 'common_name',
    allowSearchFilter: false,
  };
  public dropdownSettingsProfil: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de profil disponibles',
    idField: '_id',
    textField: 'name',
    allowSearchFilter: false,
  };
  private projectId: any;
  private userIdRrf: any;
  projectEvents: any[] = [];
  projectProfiles: any[] = [];
  isSaveAuthorised = false;
  searchedIpn: any;
  control = new FormControl('');
  personSearchResults: Person[]=[];
  filteredPersons: Observable<Person[]>;
  searchValue?: string;
  selectedPerson: any;
  affectedPersonProfiles: any = []
  isSubmitted: boolean = false;
  personsId: any = [];
  isPushed: boolean = false;
  tempProfiles: any = [];
  profils: any[];
  projectName: any;
  projectEntity: any;
  affaires: any;
  projectEntities: any;
  personOriginResult: any;
  selectedPersons: any = [];
  selectedPersonsGrouped: any = [];

  constructor(private _location: Location, private router: Router, private projectService: ProjetService, private toastr: ToastrService, private ref: ChangeDetectorRef) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state?.projectId) {
      this.projectName = state.projectName;
      this.projectId = state.projectId;
      this.userIdRrf = state.idRrf;
      this.getProfiles();
    } else {
      this.router.navigateByUrl('project/home');
    }
  }
  backClicked() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  private _filter(value: any): Person[] {
    const filterValue = value.toLowerCase();
    return this.personSearchResults.filter(state => state.firstName?.toLowerCase().includes(filterValue));
  }


  onSelectRow(item:any): void {
    if(item.mail === undefined || item.mail === null || item.mail === '') {
      this.toastr.error("Cet utilisateur n'a pas de mail. Veuillez renseigner un mail pour l'ajouter.");
      item.isSelected = false;
      return;
    }
    if(item.mail !== undefined && item.mail !== null && (item.mail)?.includes("reseau.renault.fr")) {
      this.toastr.error("L'adresse email de cet utilisateur est invalide !");
      item.isSelected = false;
      return;
    }
    else{
      this.affaires = item.entity;
      this.projectProfiles.forEach(p => { p.isChecked = false; });
      this.selectedPerson = item;
      this.handleCheckedPofile(this.selectedPerson);
      this.personSearchResults.forEach(p => {if(p.mail !== item.mail) p.isSelected = false;});
      item.isSelected = !item.isSelected;
    }
  }
  
  mailIsValid(mail: any): boolean {
    if(mail === undefined || mail === null || mail === '') {
      return true;
    }
    if(mail !== undefined && mail !== null && (mail)?.includes("reseau.renault.fr")) {
      return true;
    }
      return false;

  }

  handleCheckedPofile(selectedPerson) {
    if (selectedPerson) {
      this.projectProfiles.forEach(p => {
        const resp = p.responsables.find(r => r.login === selectedPerson.login);
        p.isChecked = resp !== undefined;
      });
    }
  }

  getEvents() {
    this.projectService.getProjectEventsByProjectIdAndUserIdRrf(this.projectId, this.userIdRrf).then(response => {
      this.projectEvents = response || [];
    });
  }

  getProfiles() {

    this.projectService.getProjectProfilesRSI(this.projectId,this.userIdRrf).then(async response => {
      this.projectProfiles = response.result.profiles;
      this.projectEntities=response.result.entities;
      this.profils=[...this.projectProfiles]
      this.profils.forEach(async profil => {
       if (profil.responsables) {
        let responsables=[];
        profil.responsables.forEach(r=>{
          if(responsables.filter(s=>s.fullName==r.fullName).length==0){
            r.entitySelected=[r.entitySelected];
           responsables.push(r);
          }else{
            let rSelected=responsables.find(s=>s.fullName==r.fullName);
            if(rSelected.entitySelected.filter(e=>e.common_name==r.entitySelected.common_name).length==0){
            rSelected.entitySelected.push(r.entitySelected);
            }
          }
        })
        profil.responsables=responsables;
        }
      });

    this.filteredPersons = this.control.valueChanges.pipe(
      startWith(''),
      map(Person => (Person ? this._filter(Person) : this.personSearchResults.slice())),
    );
    });
  }

  checkIfCanSave() {
    const notFoundResponsible = this.profils.filter(p => !p.responsables || p.responsables.length === 0);
    this.isSaveAuthorised = notFoundResponsible.length === 0;
  }

  extractResp(){
    const res = []
    this.profils.forEach(p => {
     const resps = p.responsables as any[];

     if(resps != undefined && resps != null){
        resps.forEach(r => {
          const prenom = r.firstName;
          const nom = r.lastName;          
          const entities = r.entitySelected as any[];

          if(entities != undefined && entities.length != undefined){
            entities.forEach(e => {
              var val = {} as any;
              val['nom'] = nom;
              val['prenom'] = prenom;
              val['code_entite'] = e.code_entite;
              res.push(val);
            });
          }
        });
     }
    });
    return res
  }

  async save() {
    let responsablesUpdate = [];
    let entities=[];
    this.projectProfiles.forEach(p => {
      let responsables = Object.assign([], this.profils.find(f => f._id == p._id).responsables);
      entities.push(...p.entities.map(e=>e.event_entity));
      p.entities.forEach(entity=>{
        let responsablesEntity = responsables.filter(res => res.entitySelected && res.entitySelected.find(e => e.code_entite == entity.code_entite));
        if(responsablesEntity.length>0){
          responsablesEntity.forEach(r => {
          let rN = {} as any;
          rN.event_entity=entity.event_entity;
          if(r.loginEntite){
          rN.login = r.loginEntite.find(po => po.code_entite == entity.code_entite).login;
          }else{
            rN.login=r.login;
          }
          rN.lastName = r.lastName;
          rN.firstName = r.firstName;
          rN.fullName = r.fullName;
          rN.mail = r.mail;
          responsablesUpdate.push(rN);
        })
      }
      })
    });
    this.projectService.setProjectProfilesResponsables(entities, responsablesUpdate).then( response => {
      this.toastr.success('Les profiles ont bien étés mis à jour.');
    });
  }


  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  pick = ({login,code_entite}) => ({login,code_entite})

  filtertTab(loginsEntite:any[], selectedEntity:any[],fullName:string,mail:string) {
    return  loginsEntite.filter(u => selectedEntity.find( e => e.code_entite === u.code_entite && (u.fullName.toLowerCase() === fullName.toLowerCase() || u.mail === mail)));     
  }

  async searchPerson(name:any) {
    this.personSearchResults = [];
    this.personOriginResult = [];
    var entities = this.projectEntities.map(elt => elt.code_entite);
    let res = await this.projectService.searchPerson(entities,name);
    let resPers = res.result  as any[];
    for(let elt of resPers){
      var pers = resPers.filter(e => e.fullName == elt.fullName);
      var entityMap = pers.map(e => e.code_entite);
      var entity = this.projectEntities.filter(e => entityMap.includes(e.code_entite));
      var entitylogin = pers.map(e => {var val = {}; val['login'] = e.login; val['code_entite'] = e.code_entite; return val; });
      var personCpy = elt;
      personCpy['loginEntite'] = entitylogin;
      personCpy['entity'] = entity;
      personCpy['isSelected '] = false;      
      this.personSearchResults.push(personCpy);
    }
    
    this.personSearchResults = this.getUniqueListBy(this.personSearchResults, 'fullName') as any[];
    this.personOriginResult = this.personSearchResults;
    this.personSearchResults.sort(function(a, b) {
      return a.fullName.localeCompare(b.fullName)
  });
  }


  setProfileResponsable(selectedPerson) {
    selectedPerson.profilSelected.forEach(p => {
      let profile = this.profils.filter(po => po._id == p._id)[0];
      profile.responsables = profile.responsables.filter(r => r.login != selectedPerson.login);
      profile.responsables.push({ ...selectedPerson });
    });
    selectedPerson.profilSelected = null;
    selectedPerson.entitySelected = null;
    this.checkIfCanSave();
  }


  getTitle(person) {
    if (person.entitySelected) {
      var toolTipVals = person.entitySelected.map(e => e.common_name);
      return toolTipVals.join()
    } else {
      return null;
    }
  }


  onChange() {
    this.selectedPerson = "";
    this.searchPerson(this.searchValue)
    this.ref.detectChanges();
  }

  removeProfile(profile) {
    profile.isChecked = false;
    profile.responsables = [];
    this.checkIfCanSave();
  }

  removePerson(login, profile) {
    const index = profile.responsables.findIndex(ap => ap.login === login);
    if (index !== -1) {
      profile.responsables.splice(index, 1);
    }
    if (this.selectedPerson && this.selectedPerson.login === login) {
      profile.isChecked = false;
    }
    this.checkIfCanSave();
  }

}
